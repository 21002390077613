export const UPDATE_DRAFT = 'updateDraft';
export const UPDATE_DRAFT_DESCRIPTION = 'updateDraftDescription';
export const UPDATE_CHAT_ENABLED = 'updateChatEnabled';
export const UPDATE_DRAFT_NAME = 'updateDraftName';

export const ADD_SECTION = 'addSection';
export const COMMIT_ADD_SECTION = 'commitAddSection';
export const DELETE_SECTION = 'deleteSection';
export const COMMIT_DELETE_SECTION = 'commitDeleteSection';
export const UPDATE_SECTION = 'updateSection';
export const COMMIT_UPDATE_SECTION = 'commitUpdateSection';
export const UPDATE_SECTIONS = 'updateSections';

export const VIEW_PAGE_BY_ID = 'viewPageById';
export const VIEW_PREV_PAGE = 'viewPrevPage';
export const VIEW_NEXT_PAGE = 'viewNextPage';
export const VIEW_CLOSEST_FULLSCREEN_VISIBLE_PAGE = 'viewClosestFullscreenVisiblePage';

export const ADD_PAGE = 'addPage';
export const DELETE_PAGES = 'deletePages';
export const UPDATE_PAGE = 'updatePage';
export const UPDATE_PAGES = 'updatePages';
export const COMMIT_UPDATE_PAGE = 'commitUpdatePage';
export const COMMIT_DELETE_PAGE = 'commitDeletePage';

export const UPDATE_SHARES = 'updateShares';
export const UPDATE_SHARE_ID = 'updateShareId';

export const SET_ALLOW_LOADING_NEXT_ROUTE_CHANGE = 'setAllowLoadingNextRouteChange';
export const UPDATE_LOADING = 'updateLoading';
export const UPDATE_SAVING = 'updateSaving';
export const UPDATE_INITIALIZED = 'updateInitialized';
export const UPDATE_PUBLISHED_ID = 'updatePublishedId';
export const UPDATE_TEI_ERRORS = 'updateTEIErrors';

export const COPY_MODULE = 'copyModule';
export const PASTE_MODULE = 'pasteModule';
export const DUPLICATE_MODULE = 'duplicateModule';
export const CREATE_PAGE = 'createPage';
export const COPY_PAGES = 'copyPages';
export const CREATE_DRAFT = 'createDraft';
export const SAVE_DRAFT = 'saveDraft';
export const CREATE_CREATION = 'createCreation';
export const COPY_CREATION = 'copyCreation';
export const PUBLISH_DRAFT = 'publishDraft';
export const SEND_API_REQUEST = 'sendRequest';
export const CLEAR_DRAFT = 'clearDraft';
export const GET_DRAFT = 'getDraft';
export const SET_DRAFT = 'setDraft';
export const GET_CREATION = 'getCreation';
export const SET_ACCESS_LEVEL = 'setAccessLevel';
export const MARK_AS_COMPLETE = 'markAsComplete';
export const DELETE_DRAFT = 'deleteDraft';
export const UPDATE_PAGE_OPTIONS = 'updatePageOptions';
export const UPDATE_PAGES_OPTIONS = 'updatePagesOptions';
export const UPDATE_ALL_PAGE_OPTIONS = 'updateAllPageOptions';
export const RESTORE_CREATION = 'restoreCreation';

export const CACHE_GRID_MATRIX = 'cacheGridMatrix';
export const DISABLE_GRID_FOR_PAGE = 'disableGridForPage';

export const INIT = 'init';
export const INIT_LIB = 'initLib';
export const UPDATE_L10N = 'updateL10n';
export const START_POLL = 'startPoll';
export const KILL_POLL = 'killPoll';

export const OPEN_MODAL = 'openModal';
export const CLOSE_MODAL = 'closeModal';
export const TOGGLE_MODAL = 'toggleModal';
export const CLEAR_MODALS = 'clearModals';
export const SET_MY_CONTENT_MODAL_OPTIONS = 'setMyContentModalOptions';
export const SET_ACTIVE_CANVAS_NAME = 'setActiveCanvasName';

export const SEND_EVENT = 'sendEvent';
export const SET_USER = 'setUser';
export const GET_USER = 'getUser';
export const CACHE_USER = 'cacheUser';
export const SEND_CLICKLOG_EVENT = 'sendClicklogEvent';
export const SET_STUDENT_VIEW = 'setStudentView';

export const CLOSE_EDITOR_DRAWERS = 'closeEditorDrawers';
export const TOGGLE_EDITOR_DRAWER = 'toggleEditorDrawer';
export const SET_EDITOR_STATUS_MESSAGE = 'setEditorStatusMessage';
export const SET_DRAFT_IS_DIRTY = 'setDraftIsDirty';
export const SET_ERROR = 'setError';
export const SET_STUDIO_TOAST = 'setStudioToast';

export const GET_TEMPLATES = 'getTemplates';
export const SET_TEMPLATES = 'setTemplates';

export const CACHE_ASSETS_AND_UPLOADS = 'cacheAssetsAndUploads';
export const CACHE_ASSET = 'cacheAsset';
export const GET_ASSET = 'getAsset';
export const CACHE_UPLOAD = 'cacheUpload';
export const CACHE_UPLOADS = 'cacheUploads';
export const GET_ASSET_PLAYER_LINK = 'getAssetPlayerLink';
export const SET_ASSET_PLAYER_LINK = 'setAssetPlayerLink';

export const SET_PRODUCT_SETTINGS = 'setProductSettings';
export const GET_PRODUCT_SETTINGS = 'getProductSettings';

export const SET_EDITOR_IS_FULLSCREEN = 'setEditorIsFullscreen';
export const SET_EDITOR_VIEW_MODE_PREVIEW = 'setEditorViewModePreview';
export const SET_EDITOR_SNAP_GRID = 'setEditorSnapGrid';
export const SET_EDITOR_CAROUSEL_PAGE_INDEX = 'setEditorCarouselPageIndex';
export const SET_EDITOR_CAROUSEL_IS_ANIMATED = 'setEditorCarouselIsAnimated';

export const SET_TOP_NAV_DROPDOWN_IS_OPEN = 'setTopNavDropdownIsOpen';
export const SET_TOP_NAV_DRAWER_IS_OPEN = 'setTopNavDrawerIsOpen';
export const SET_ADD_MODULE_DRAWER_IS_OPEN = 'setAddModuleDrawerIsOpen';

export const UPDATE_PAGE_HEIGHT = 'updatePageHeight';

// Assessment module
export const GET_ASSESSMENT = 'getAssessment';
export const SET_ASSESSMENT = 'setAssessment';
export const CACHE_TEI = 'cacheTei';
export const CACHE_TEIS = 'cacheTeis';

export const GET_ATTEMPT = 'getAttempt';
export const SET_ATTEMPT = 'setAttempt';
export const SEND_ATTEMPT_PATCH = 'sendAttemptPatch';
export const THROTTLE_SEND_ATTEMPT_PATCH = 'throttleSendAttemptPatch';
export const SUBMIT_ATTEMPT = 'submitAttempt';
export const SET_ALLOW_EDIT_ATTEMPT = 'setAllowEditAttempt';
export const UPDATE_UNSUBMITTED_ATTEMPT = 'updateUnsubmittedAttempt';
export const CLEAR_ATTEMPT = 'clearAttempt';
export const SET_ATTEMPT_CLEARING = 'setAttemptClearing';

export const SUBMIT_RESPONSE = 'submitResponse';
export const UPDATE_RESPONSE = 'updateResponse';

// Route storage
export const SET_STUDIO_REFERER = 'setStudioReferer';

// Chat module
export const GET_CHAT = 'getChat';
export const SET_CHAT = 'setChat';
export const CLEAR_CHAT_STORE = 'clearChatStore';
export const UPDATE_CHAT = 'updateChat';
export const SET_CONNECTED = 'setConnected';
export const SEND_CHAT_MESSAGE = 'sendChatMessage';
export const UPDATE_MESSAGE_IS_HIDDEN = 'updateMessageIsHidden';
export const SET_CHAT_TOGGLE_ENABLED = 'setChatToggleEnabled';
export const QUEUE_CHAT_MESSAGE = 'queueChatMessage';
export const REMOVE_QUEUED_CHAT_MESSAGE = 'removeQueuedChatMessage';

// Home module
export const GET_HOME_BOARDS = 'getHomeBoards';
export const SET_HOME_BOARDS = 'setHomeBoards';
export const SET_HOME_ERROR = 'setHomeError';
export const SET_HOME_LOADING = 'setHomeLoading';
export const GET_ACTIVITY_TEMPLATES = 'getActivityTemplates';
export const SET_ACTIVITY_TEMPLATES = 'setActivityTemplates';

// Inactivity module
export const START_AUTH_CHECK = 'startAuthCheck';
export const AUTH_CHECK = 'AuthCheck';
export const END_AUTH_CHECK = 'endAuthCheck';
export const SET_AUTH_CHECK_TIMEOUT = 'setAuthCheckTimeout';
export const CHECK_AUTH_STATUS = 'checkAuthStatus';
export const SET_ACTIVITY_HANDLER = 'setActivityHandler';

// Preview
export const CREATE_PREVIEW = 'createPreview';
export const SET_IS_PREVIEW_MODE = 'setIsPreviewMode';

// Collaborative editing
export const SET_IS_CONNECTED = 'setIsConnected';
export const SET_SESSION = 'setSession';
export const SET_CONNECTED_USERS = 'setConnectedUsers';
export const SET_CONNECTED_USERS_COLORS = 'setConnectedUsersColors';
export const OPEN_EDITING_SESSION = 'openEditingSession';
export const CLOSE_EDITING_SESSION = 'closeEditingSession';
export const GET_DRAFT_REFERENCES = 'getDraftReferences';
export const GET_SHARED_DOCUMENT = 'getSharedDocument';
export const SET_SHARED_DOCUMENT = 'setSharedDocument';
export const ENQUEUE_DRAFT_PATCH = 'enqueueDraftPatch';
export const INIT_DRAFT = 'initDraft';
export const UPDATE_MODULE = 'updateModule';
export const COMMIT_UPDATE_MODULE = 'commitUpdateModule';
export const APPLY_UPDATE_MODULE = 'applyUpdateModule';
export const ADD_MODULE = 'addModule';
export const COMMIT_ADD_MODULE = 'commitAddModule';
export const APPLY_ADD_MODULE = 'applyAddModule';
export const REPLACE_MODULE = 'replaceModule';
export const DELETE_MODULE = 'deleteModule';
export const APPLY_DELETE_MODULE = 'applyDeleteModule';
export const APPLY_UPDATE_DRAFT = 'applyUpdateDraft';
export const UPDATE_CURRENTLY_EDITING = 'updateCurrentlyEditing';
export const MOVE_PAGES = 'movePages';
export const APPLY_ADD_PAGE = 'applyAddPage';
export const APPLY_UPDATE_PAGE = 'applyUpdatePage';
export const APPLY_DELETE_PAGE = 'applyDeletePage';
export const ENQUEUE_PAGE_PATCH = 'enqueuePagePatch';
export const APPLY_UPDATE_DRAFT_OPTIONS = 'applyUpdateDraftOptions';
export const APPLY_UPDATE_SECTION = 'applyUpdateSection';
export const APPLY_UPDATE_PAGE_OPTIONS = 'applyUpdatePageOptions';
export const APPLY_UPDATE_DRAFT_METADATA = 'applyUpdateDraftMetadata';
export const UPDATE_DRAFT_PUBLISHED_ON = 'updateDraftPublishedOn';
export const CHECK_SORT_INDEXES = 'checkSortIndexes';
export const CHECK_MODULE_Z_POSITIONS = 'checkModuleZPositions';
export const UPDATE_DRAFT_CHAT_ENABLED = 'updateDraftChatEnabled';
export const UPDATE_MODULES = 'updateModules';
export const SET_CURRENT_PAGE_FOR_CONNECTED_USER = 'setCurrentPageForConnectedUser';
export const ADD_MODULE_CONFLICT_MESSAGE = 'addModuleConflictMessage';
export const SHOW_ADD_MODULE_CONFLICT_MESSAGES = 'showAddModuleConflictMessages';

// Undo / Redo
export const INIT_DRAFT_UNDO = 'initDraftUndo';
export const UNDO_DRAFT_EDIT = 'undoDraftEdit';
export const REDO_DRAFT_EDIT = 'redoDraftEdit';
export const REMOVE_MODULES_FROM_UNDO_HISTORY = 'removeModulesFromUndoHistory';
export const REMOVE_FROM_UNDO_HISTORY = 'removeFromUndoHistory';
export const CLOSE_UNDO_TRACKING = 'closeUndoTracking';
export const VIEW_UNDO_REDO_ACTION = 'viewUndoRedoAction';

// Messaging
export const SET_APP_MESSAGE = 'setAppMessage';

// QuickList
export const GET_QUICKLIST = 'getQuicklist';
export const SET_QUICKLIST = 'setQuicklist';
export const SET_QUICKLIST_IDS = 'setQuicklistIds';
export const ADD_TO_QUICKLIST = 'addToQuicklist';
export const REMOVE_FROM_QUICKLIST = 'removeFromQuicklist';
export const REMOVE_ALL_FROM_QUICKLIST = 'removeAllFromQuicklist';
export const MOVE_QUICKLIST_ITEM = 'moveQuicklistItem';
export const LOAD_MORE_QUICKLIST = 'loadMoreQuicklist';

// Channels
export const GET_CHANNELS = 'getChannels';
export const SET_CHANNELS = 'setChannels';
export const SUBSCRIBE_TO_CHANNEL = 'subscribeToChannel';
export const UNSUBSCRIBE_FROM_CHANNEL = 'unsubscribeFromChannel';

// Url Request Cache
export const CACHE_URL_REQUEST = 'cacheUrlRequest';

// Thumbnails
export const TRACK_THUMBNAIL_LOADER = 'trackThumbnailLoader';
export const SET_TRACK_THUMBNAIL_LOADERS = 'setTrackThumbnailLoaders';
export const CLEAR_THUMBNAIL_LOADERS = 'clearThumbnailLoaders';
export const SET_OVERLAY_THUMBNAIL = 'setOverlayThumbnail';

export const CLEAR_CURRENT_USER_EDITING = 'clearCurrentlyEditing';
export const PASTE_MODULE_FROM_CLIPBOARD = 'pasteModuleFromClipboard';
export const PASTE_MODULE_FROM_GUID = 'pasteModuleFromGuid';

// Modal Canvases
export const ADD_MODAL_CANVAS = 'addModalCanvas';
export const APPLY_ADD_MODAL_CANVAS = 'applyAddModalCanvas';
export const ENQUEUE_MODAL_PATCH = 'enqueueModalPatch';
export const VIEW_MODAL_CANVAS_BY_ID = 'viewModalCanvasById';
export const CLOSE_MODAL_CANVAS = 'closeModalCanvas';
export const ADD_MODAL_MODULE = 'addModalModule';
export const COMMIT_ADD_MODAL_MODULE = 'commitAddModalModule';
export const APPLY_ADD_MODAL_MODULE = 'applyAddModalModule';
export const DELETE_MODAL_MODULE = 'deleteModalModule';
export const APPLY_DELETE_MODAL_MODULE = 'applyDeleteModalModule';
export const APPLY_UPDATE_MODAL_MODULE = 'applyUpdateModalModule';
export const UPDATE_MODAL_MODULE = 'updateModalModule';
export const COMMIT_UPDATE_MODAL_MODULE = 'commitUpdateModalModule';
export const UPDATE_MODAL = 'updateModal';
export const COMMIT_UPDATE_MODAL = 'commitUpdateModal';

// UI
export const SET_BULK_ACTION_PAGE_IDS = 'setBulkActionPageIds';
export const ADD_BULK_ACTION_PAGE_ID = 'addBulkActionPageId';
export const REMOVE_BULK_ACTION_PAGE_ID = 'removeBulkActionPageId';

// Experiments
export const SET_EXPERIMENT_LIST = 'setExperimentList';
export const GET_EXPERIMENT_LIST = 'getExperimentList';

// Videos
export const SET_DURATION = 'setDuration';

export const SET_PROGRESS_TRACKING_DATA = 'setProgressTrackingMetadata';
export const TRACK_PROGRESS = 'trackProgressAction';
export const SET_PROGRESS_COMPLETE = 'setProgressComplete';
export const SET_ACTIVITY_SUMMARY = 'setActivitySummary';
export const SET_ATTEMPT_STARTED = 'setAttemptStarted';

// Text Select
export const SET_HIGHLIGHTS = 'setHighlights';
export const ADD_HIGHLIGHT = 'addHighlight';
export const REMOVE_HIGHLIGHT = 'removeHighlight';
export const UPDATE_HIGHLIGHT = 'updateHighlight';
export const GET_HIGHLIGHT_DATA = 'getHighlightData';
export const CLEAR_HIGHLIGHT_DATA = 'clearHighlightData';

// TRACKING
export const SET_IS_ANONYMOUS = 'setIsAnonymous';
export const GET_ANONYMOUS_ACTIVITY_SUMMARY = 'getAnonymousActivitySummary';
export const STORE_ANONYMOUS_SUMMARY = 'storeAnonymousSummary';
export const SET_ANONYMOUS_ALLOWED = 'setAnonymousAllowed';
export const UPDATE_ATTEMPTED_TEI = 'updateAttemptedTEI';
export const STORE_VIDEO_PLAY_PERCENTAGE = 'storeVideoPlays';
export const STORE_PAGE_VIEW_PERCENTAGE = 'setPageHeightViewed';
export const SET_EVENTS_ENABLED = 'setEventsEnabled';
export const SET_ASSIGNMENT_ID = 'setAssignmentId';
export const START_SESSION_TRACKING = 'startSessionTracking';
export const END_SESSION_TRACKING = 'endSessionTracking';
export const DISABLE_ALL_EVENTS = 'disableAllEvents';

// Confirmations
export const CONFIRM_ACTION = 'confirmAction';
export const ADD_CONFIRMATION = 'addConfirmation';
export const REMOVE_CONFIRMATION = 'removeConfirmation';
export const GET_CONFIRMATION = 'getConfirmation';
export const CLEAR_CONFIRMATIONS = 'clearConfirmations';

export const START_EVENT_QUEUE_POLLING = 'startEventQueue';
