import { defineStore } from 'pinia';
import {
  get, set, isEmpty, throttle, toLower,
} from 'lodash-es';
import { stripTags } from '@/lib/utils';
import { getTeiLang } from '@/lib/constants/teiLanguageMap';
import {
  useAppStore,
  useAssetStore,
  useEditorStore,
  useErrorStore,
  useToastStore,
  useTrackingStore,
  useUserStore,
} from '@/stores';
import { parseAssessmentAttempt } from '@/lib/utils/store';
import * as types from '@/lib/constants/store';

// Throttle PATCH requests by holding them here and submitting them in batches
let patchList = [];

const useAssessmentStore = defineStore('studio-assessment', {
  state: () => ({
    assessment: null,
    attempt: null,
    allowEditAttempt: true,
    teis: {}, // cached TEI data
    unsubmittedAttempt: false,
    attemptIsClearing: false,
    updatedTEIs: [], // temporary store for TEIS that have been updated, but not submitted
  }),
  getters: {
    pageIsFullSlideTEI: (state) => {
      const editorStore = useEditorStore();
      return !!(
        state.teis
        && editorStore.currentPage?.options
        && editorStore.currentPage.options.asset_id
        && state.teis[editorStore.currentPage.options?.asset_id]
      );
    },
    attemptedTEIs: (state) => {
      let teis = [];
      if (state.attempt?.items) {
        teis = state.attempt.items
          .filter((item) => (!!item.response || item.tries?.length))
          .map((item) => item.id);
      }

      // merge updated
      return Array.from(new Set([...teis, ...state.updatedTEIs]));
    },
    attemptHasUnansweredQuestions: (state) => (
      state.attempt && state.attempt.items.some((item) => {
        const { response } = item.try;

        // An empty prompt response contains only empty tags
        const promptResponse = get(response, 'answerFieldData[0]');
        const isEmptyPromptResponse = promptResponse !== undefined && !stripTags(promptResponse);

        return !response // the default unanswered value is an empty string
          || isEmptyPromptResponse
          || isEmpty(response); // an unanswered multiple_selection is an empty array
      })
    ),
    attemptIsSubmitted: (state) => (
      // It's considered submitted if any items have type_id === 2 (TRY_TYPE_SUBMIT)
      state.attempt && state.attempt.items.some((item) => item.try.type_id === 2)
    ),
    allTeisSubmitted: (state) => (
      state.attempt && state.attempt.items.every((item) => !!item.tries.length)
    ),
    attemptItemByTeiId: (state) => (teiId) => (
      get(state.attempt, 'items', []).find((item) => item.tei_id === teiId)
    ),
    teiLang() {
      const editorStore = useEditorStore();
      return getTeiLang(editorStore.draft?.language?.code);
    },
    userCanEditAttempt(state) {
      const editorStore = useEditorStore();
      // The user can make PATCHes to their attempt if it hasn't been submitted yet.
      // It's considered submitted if any items have type_id === 2 (TRY_TYPE_SUBMIT)
      // or 9 (TRY_TYPE_SCORE_OVERWRITTEN)
      // Full-slide TEI tries can be submitted multiple times, so they are an
      // exception
      return state.allowEditAttempt
        && state.attempt
        && (!state.attempt.items.some((item) => item.try.type_id === 2 || item.try.type_id === 9)
          || !!editorStore.draft?.tei_page_asset_ids?.length);
    },
  },
  actions: {
    async [types.CACHE_TEI]({ teiId }) {
      const editorStore = useEditorStore();
      const userStore = useUserStore();
      if (!teiId || this.teis[teiId]) {
        // Return if we've already cached this TEI data
        return;
      }

      try {
        let response;

        if (editorStore.loadAssetsFromContentApi) {
          // DE editors editing lessons use the content api
          response = await this.contentApi.get(`/api/v1/assets/${teiId}/extended/data`);
        } else if (userStore.userIsStudent) {
          // Students viewing a lesson need TEI data without the answer key
          response = await this.deApi.get(`/teis/question/${teiId}`);
        } else {
          // Non-students viewing a lesson need the TEI data with the answer key
          response = await this.deApi.get(`/teis/${teiId}?embed=tei_data`);
        }

        if (response.status !== 200 || !response.data) throw response;

        // These two apis format the response slightly differently
        this.teis[teiId] = {
          ...(response.data.data || response.data.tei_data),
          id: teiId,
        };
      } catch (error) {
        // Display either the error message from Python or the Axios error itself
        this.teis[teiId] = {
          error: get(error, 'response.data.meta', error),
          id: teiId,
        };
      }
    },
    [types.CACHE_TEIS]() {
      const editorStore = useEditorStore();
      if (editorStore.draft.tei_page_asset_ids?.length) {
        editorStore.draft.tei_page_asset_ids.forEach((teiId) => {
          this[types.CACHE_TEI]({ teiId });
        });
      }
    },
    async [types.GET_ASSESSMENT]({ assessmentId }) {
      try {
        const result = await this.deApi.get(`/assessments/${assessmentId}?embed=questions`);

        if (result.status !== 200) throw result;

        this.assessment = result.data.assessment;
      } catch (error) {
        const errorStore = useErrorStore();
        errorStore[types.SET_ERROR]({
          active: true,
          error: get(error, 'response.data') || {
            meta: {
              message: error,
            },
          },
        });
      }
    },
    async [types.SET_ATTEMPT_CLEARING](value) {
      this.attemptIsClearing = value;
    },
    async [types.CLEAR_ATTEMPT]() {
      const appStore = useAppStore();
      const editorStore = useEditorStore();
      const assessmentId = editorStore.draft.references.find(
        (ref) => ref.reference_type === 'asset' && ref.reference_subtype === 'revision',
      ).reference_id;

      const apiUrl = '/assessments/delete_attempts/teacher';
      const bodyFormData = new FormData();
      bodyFormData.append('assessment_id', assessmentId);

      let result;

      this[types.SET_ATTEMPT_CLEARING](true);

      // send request
      try {
        result = await this.deApi.post(
          apiUrl,
          bodyFormData,
          {
            headers: {
              'X-Token': appStore.apiToken,
            },
          },
        );
        if (result.status !== 200) throw result;

        // success? grab a new attempt
        await this[types.GET_ATTEMPT]({ assessmentId });
      } catch (error) {
        const errorStore = useErrorStore();
        errorStore[types.SET_ERROR]({
          active: true,
          error: get(error, 'response.data') || {
            meta: {
              message: error,
            },
          },
        });
      }

      return result;
    },
    async [types.GET_ATTEMPT](
      {
        assessmentId,
        homeworkId,
        userId,
        ignoreError,
      },
    ) {
      const userStore = useUserStore();
      const appStore = useAppStore();
      const errorStore = useErrorStore();
      const trackingStore = useTrackingStore();

      let attemptUrl = `/assessments/${assessmentId}/attempts`;
      if (homeworkId) {
        attemptUrl += `?homework_id=${homeworkId}`;

        if (userId) {
          attemptUrl += `&user_guid=${userId}`;
        }
      }

      try {
        let newAttemptCreated = false;

        // First, get any existing attempts
        const result = await this.deApi.get(attemptUrl);
        if (result.status !== 200) throw result;

        // Use the first attempt, filtering by homework id if necessary
        const validAttempt = result.data.attempts.find((attempt) => (
          !homeworkId || toLower(attempt.assignment_id) === toLower(homeworkId)
        ));

        // If no previous valid attempt was returned, we need to create one
        if (!validAttempt) {
          // Teachers that are viewing a student's attempt shouldn't try to create a new attempt
          if (!userStore.userIsStudent && homeworkId) return;

          const newAttempt = await this.deApi.post(
            attemptUrl,
            {}, // no POST body is required
            {
              headers: {
                'X-Token': appStore.apiToken,
              },
            },
          );
          if (newAttempt.status !== 200) throw newAttempt;

          newAttemptCreated = true;

          this[types.SET_ATTEMPT](parseAssessmentAttempt(newAttempt.data.attempt));
        } else {
          this[types.SET_ATTEMPT](parseAssessmentAttempt(validAttempt));
        }

        // after an attempt has been set, see if it's submitted
        // OR if the attempt user isn't the same as current user
        if (this.attemptIsSubmitted || (!userStore.userIsStudent && homeworkId)) {
          // disable tracking
          trackingStore[types.DISABLE_ALL_EVENTS]();
        } else if (newAttemptCreated) {
          trackingStore[types.SEND_EVENT]({ eventType: 'attempt-started' });
        }
      } catch (error) {
        // If there's any trouble getting or creating an attempt, show the error
        if (!ignoreError) {
          errorStore[types.SET_ERROR]({
            active: true,
            error: get(error, 'response.data') || {
              meta: {
                message: error,
              },
            },
          });
        }
      }
    },
    [types.SET_ATTEMPT](attempt) {
      const assetStore = useAssetStore();
      // are there assets in the attempt?
      if (attempt && attempt.items) {
        attempt.items.forEach((attemptItem) => {
          const assetId = attemptItem.try?.response?.moduleData?.assetId;

          // cache asset
          if (assetId) {
            assetStore[types.CACHE_ASSET](assetId);
          }
        });
      }

      this.attempt = attempt;
    },
    async [types.SUBMIT_ATTEMPT]() {
      const trackingStore = useTrackingStore();

      // first clear out any patches, as we'll be submitting new ones
      patchList = [];

      this.attempt.items.forEach((attemptItem, attemptItemIdx) => {
        if (attemptItem.try?.response) {
          // To submit an attempt, all tries need to be changed to TRY_TYPE_SUBMIT
          patchList.push({
            op: 'replace',
            path: `/items/${attemptItemIdx}/try/type_id`,
            value: 2, // TRY_TYPE_SUBMIT
          });

          // also submit the response to match up with the new type
          patchList.push({
            op: 'replace',
            path: `/items/${attemptItemIdx}/try/response`,
            value: JSON.stringify(attemptItem.try.response),
          });
        }
      });

      // send tracking store
      trackingStore[types.SEND_EVENT]({ eventType: 'attempt-submitted' });

      await this[types.SEND_ATTEMPT_PATCH]();
    },
    async [types.SEND_ATTEMPT_PATCH]() {
      const appStore = useAppStore();
      const toastStore = useToastStore();
      if (!patchList.length) return;

      const assessmentId = this.attempt.assessment_id;
      const attemptId = this.attempt.id;

      // Stage this request's patches
      const stagedPatchList = patchList;
      patchList = [];
      try {
        const response = await this.deApi.patch(
          `/assessments/${assessmentId}/attempts/${attemptId}`,
          stagedPatchList,
          {
            headers: {
              'X-Token': appStore.apiToken,
            },
          },
        );
        if (response.status !== 200 || !response.data?.attempt) throw response;

        // The attempt that gets returned contains scoring data, so save it
        this.attempt = parseAssessmentAttempt(response.data.attempt);
      } catch (e) {
        // If the PATCH fails, flash a toast message and add our patches back into the list
        const errorMessage = get(e, 'response.data.meta.message');
        toastStore[types.SET_STUDIO_TOAST]({ type: 'error', message: errorMessage });
        patchList = [...stagedPatchList, ...patchList];
      }
    },
    [types.THROTTLE_SEND_ATTEMPT_PATCH]: throttle((store) => {
      store[types.SEND_ATTEMPT_PATCH]();
    }, 5000),
    [types.UPDATE_RESPONSE]({ payload, teiId }) {
      const trackingStore = useTrackingStore();
      const attemptItemIdx = get(this, 'attempt.items', [])
        .findIndex((attemptItem) => attemptItem.tei_id === teiId);

      // Add our updated response to the PATCH queue
      patchList.push({
        op: 'replace',
        path: `/items/${attemptItemIdx}/try/response`,
        value: JSON.stringify(payload), // TEI responses are stringified in the DB
      });

      this[types.THROTTLE_SEND_ATTEMPT_PATCH](this);

      // update the store
      set(this, `attempt.items[${attemptItemIdx}].try.response`, payload);

      // send the event
      trackingStore[types.SEND_EVENT]({ eventType: 'tei.update' });
    },
    [types.SUBMIT_RESPONSE]({ payload, teiId }) {
      const userStore = useUserStore();
      const trackingStore = useTrackingStore();
      const attemptItemIdx = get(this, 'attempt.items', [])
        .findIndex((attemptItem) => attemptItem.tei_id === teiId);

      // Add our updated response to the PATCH queue
      patchList.push({
        op: 'replace',
        path: `/items/${attemptItemIdx}/try/response`,
        value: JSON.stringify(payload), // TEI responses are stringified in the DB
      });

      // Update the try to TRY_TYPE_SUBMIT only for students
      // try type will remain SAVE for teachers to allow unlimited submits
      if (userStore.userIsStudent) {
        patchList.push({
          op: 'replace',
          path: `/items/${attemptItemIdx}/try/type_id`,
          value: 2, // TRY_TYPE_SUBMIT
        });
      }

      // send the event
      trackingStore[types.SEND_EVENT]({ eventType: 'tei.update' });

      this[types.THROTTLE_SEND_ATTEMPT_PATCH](this);
    },
    [types.SET_ALLOW_EDIT_ATTEMPT](allowEdit) {
      this.allowEditAttempt = allowEdit;
    },
    [types.UPDATE_UNSUBMITTED_ATTEMPT](unsubmittedAttempt) {
      const editorStore = useEditorStore();
      const trackingStore = useTrackingStore();

      this.unsubmittedAttempt = unsubmittedAttempt;

      // grab the current tei id and apply to the attempted list
      if (unsubmittedAttempt) {
        const teiId = editorStore.currentPage.options.asset_id;

        if (teiId) {
          this[types.UPDATE_ATTEMPTED_TEI](teiId);
        }

        // send the event
        trackingStore[types.SEND_EVENT]({ eventType: 'tei.update' });
      }
    },
    [types.UPDATE_ATTEMPTED_TEI](teiId) {
      if (!this.attemptedTEIs.includes(teiId)) {
        this.attemptedTEIs.push(teiId);
      }
    },
  },
});

export default useAssessmentStore;
