// Note: This file is adapted from
// edde/edde/templates/edde/nav/includes/static_resource_includes/_analytics_javascript.html

export function initPendo(analyticsData) {
  let visitorId;

  window.analyticsPropertiesNow = new Date();

  window.analyticsProperties = {
    isImpersonation: analyticsData.request_info.is_impersonation,
    lastSchoolYearLogin: analyticsData.request_info.stats?.last_school_year_login_isodate,
    previousSchoolYearLogin: analyticsData.request_info.stats?.previous_school_year_login_isodate,
    applicationViewName: analyticsData.request_info.view_name,
    applicationRequestUrl: analyticsData.request_info.request_url,
    locale: {
      code: analyticsData.locale && analyticsData.locale.code,
      textDirectionLTR: analyticsData.locale && analyticsData.locale.text_direction_is_ltr,
    },
  };

  window.pendoSanitizeUrl = function pendoSanitizeUrl(url) {
    return url;
  };

  // Note: This is a public frontend key
  const apiKey = '67d353f5-3d55-4c5b-67d9-c2cbc53add89';

  /* eslint-disable */
  (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=[];
    v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
      o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
      y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
      z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
  /* eslint-enable */

  if (analyticsData.request_info.environment !== 'prod') {
    visitorId = `${analyticsData.user_id}-${analyticsData.request_info.environment}`;
  } else {
    visitorId = analyticsData.request_info.is_impersonation
      ? `${analyticsData.user_id}-impersonate`
      : analyticsData.user_id;
  }

  window.pendo.initialize({
    visitor: {
      authenticationType: analyticsData.request_info.authentication_source,
      country: analyticsData.site.country_code.toUpperCase(),
      environment: analyticsData.request_info.environment.toUpperCase(),
      id: visitorId,
      lastSignInDate: analyticsData.request_info.stats?.last_sign_in_date,
      locale: analyticsData.locale.code,
      loginCount: analyticsData.request_info.stats?.login_count,
      marketingAllowed: analyticsData.marketing_allowed,
      buildActivityAllowed: analyticsData.build_activity_allowed,
      activityTemplatesAllowed: analyticsData.activity_templates_allowed,
      spiralAllowed: analyticsData.spiral_allowed,
      platform: window.navigator.platform,
      productLicensesDaysLeft: JSON.stringify(analyticsData.products.licenses),
      ...analyticsData.products.licenses,
      productList: analyticsData.products.codes,
      campaignCodeList: analyticsData.products.campaign_codes,
      region: analyticsData.site.region_name.toUpperCase(),
      regionCode: analyticsData.site.region_code,
      schoolYearLoginCount: analyticsData.request_info.stats?.school_year_login_count,
      screenHeight: window.screen.height,
      screenWidth: window.screen.width,
      ssoURL: analyticsData.request_info.sso_url,
      userAgent: window.navigator.userAgent,
      userGradesTaught: analyticsData.user_grades_taught,
      userRoles: analyticsData.roles,
      userSubjectsTaught: analyticsData.user_subjects_taught,
    },

    account: { // DE's "Site"
      id: analyticsData.site.id,
      name: analyticsData.site.name,
    },

    parentAccount: { // DE's "Account"
      id: analyticsData.account.id,
      name: analyticsData.account.name,
    },

    sanitizeUrl(url) { // sanitizeUrl is a poorly-documented feature provided by Pendo
      let sanitizedUrl = `${url}#`;

      if (analyticsData.request_info.current_product_code) {
        sanitizedUrl += `productCode=${analyticsData.request_info.current_product_code}&`;
      }

      if (analyticsData.request_info.asset_type_name) {
        sanitizedUrl += `assetType=${analyticsData.request_info.asset_type_name}&`;
      }

      return window.pendoSanitizeUrl(sanitizedUrl);
    },
  });
}

export function loadZendesk(siteCountryCode) {
  // Add Zendesk script to the page. This needs to be added dynamically since it's supposed to be
  // included AFTER Pendo loads, which is done in javascript through GTM.
  if (siteCountryCode) {
    const zendeskScript = document.createElement('script');
    const zendeskKey = siteCountryCode === 'GB'
      ? '1f40190f-4a3b-4308-b222-fdb0a882f310'
      : '69139f7f-d202-4995-a641-71cc8f171dae';

    zendeskScript.id = 'ze-snippet';
    zendeskScript.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
    document.body.appendChild(zendeskScript);
  }
}

export function loadZoomAgent() {
  const zoomScript = document.createElement('script');
  zoomScript.setAttribute('data-apikey', 'z8zeU64oTYauNAr7l3bCbQ');
  zoomScript.setAttribute('data-env', 'us01');
  zoomScript.src = 'https://us01ccistatic.zoom.us/us01cci/web-sdk/zcc-sdk.js';

  document.body.appendChild(zoomScript);
}
