import { defineStore } from 'pinia';
import { get, last } from 'lodash-es';
import {
  useChannelStore,
  useMessageStore,
} from '@/stores';
import * as types from '@/lib/constants/store';

const useQuicklistStore = defineStore('studio-quicklist', {
  state: () => ({
    quicklist: {},
    quicklistIds: [],
  }),
  actions: {
    async [types.GET_QUICKLIST](payload = {}) {
      const channelStore = useChannelStore();
      const { allowPublicAccess } = payload;

      try {
        const qlRes = await this.deApi({
          method: 'GET',
          url: '/mycontent/quicklist',
          skipErrorHandling: allowPublicAccess || false,
        });
        const qlIdsRes = await this.deApi({
          method: 'GET',
          url: '/mycontent/quicklist/ids',
          skipErrorHandling: allowPublicAccess || false,
        });
        this.quicklist = {
          quicklist: qlRes.data.quicklist,
          tokens: qlRes.data.tokens,
        };
        this.quicklistIds = qlIdsRes.data.quicklist;

        await channelStore[types.GET_CHANNELS]();
      } catch (e) {
        if (allowPublicAccess) {
          // For publicly-accessible boards, we'd expect this to error
          return;
        }
        throw e;
      }
    },
    [types.SET_QUICKLIST_IDS](quicklistIds) {
      this.quicklistIds = quicklistIds;
    },
    async [types.ADD_TO_QUICKLIST](asset) {
      const messageStore = useMessageStore();
      try {
        const res = await this.deApi.put(`/mycontent/quicklist/add/${asset.id}`);

        if (res?.status === 200) {
          messageStore[types.SET_APP_MESSAGE]({
            type: 'QuickListMessage',
            op: 'add',
            assetName: asset.name || asset.title,
          });
          this.quicklistIds.push(asset.id);
          this.quicklist.quicklist.unshift(asset);
        } else {
          throw res;
        }
      } catch (error) {
        messageStore[types.SET_APP_MESSAGE]({
          type: 'QuickListErrorMessage',
          op: 'add',
          message: get(error, 'response.data.meta.message', error),
        });
      }
    },

    async [types.REMOVE_FROM_QUICKLIST](asset) {
      const messageStore = useMessageStore();
      try {
        const res = await this.deApi.delete(`/mycontent/quicklist/remove/${asset.id}`);

        if (res?.status === 200) {
          messageStore[types.SET_APP_MESSAGE]({
            type: 'QuickListMessage',
            op: 'remove',
            assetName: asset.name || asset.title,
          });

          const idsIdx = this.quicklistIds.indexOf(asset.id);
          if (idsIdx === -1) return;

          this.quicklistIds.splice(idsIdx, 1);

          const itemIdx = this.quicklist.quicklist.findIndex((a) => a.id === asset.id);
          if (itemIdx === -1) return;

          this.quicklist.quicklist.splice(itemIdx, 1);
        } else {
          throw res;
        }
      } catch (error) {
        messageStore[types.SET_APP_MESSAGE]({
          type: 'QuickListErrorMessage',
          op: 'remove',
          message: get(error, 'response.data.meta.message', error),
        });
      }
    },
    async [types.REMOVE_ALL_FROM_QUICKLIST]() {
      const messageStore = useMessageStore();
      const res = await this.deApi.delete('/mycontent/quicklist/removeall', {});

      if (res?.status === 200) {
        messageStore[types.SET_APP_MESSAGE]({
          type: 'QuickListDeleteAllMessage',
        });
        this.quicklistIds = [];
        this.quicklist.quicklist = [];
      } else {
        // Errors are handled in the component rather than here
        throw res;
      }
    },
    async [types.MOVE_QUICKLIST_ITEM]({ items, position, itemId }) {
      // This is not currently in use; the quicklist folder id is not accessible yet.
      // STUD-2920 will fix this
      const quicklistFolderId = 'TODO';

      this.quicklist = {
        quicklist: items,
        tokens: this.quicklist.tokens,
      };

      this.deApi.post(
        `${this.domains.appDomain}/folder/${quicklistFolderId}/asset/${itemId}/position/${position}`,
        null,
        {
          headers: {
            'X-Token': this.quicklist.tokens.reposition,
          },
        },
      );
    },
    [types.LOAD_MORE_QUICKLIST]() {
      const lastAssetId = last(this.quicklist.quicklist).id;

      this.deApi.get(`/mycontent/quicklist?after_id=${lastAssetId}`, {}, {
        remove: false,
      }).then(({ data }) => {
        this.quicklist = {
          quicklist: [...this.quicklist.quicklist, ...data.quicklist],
          tokens: this.quicklist.tokens,
        };
      });
    },
  },
});

export default useQuicklistStore;
