import { defineStore } from 'pinia';
import { get } from 'lodash-es';
import {
  useMessageStore,
} from '@/stores';
import * as types from '@/lib/constants/store';

const useChannelStore = defineStore('studio-channel', {
  state: () => ({
    channelIds: [],
  }),
  actions: {
    [types.SET_CHANNELS](channelIds) {
      this.channelIds = channelIds;
    },
    async [types.GET_CHANNELS]() {
      const channelsRes = await this.deApi.get('/home/channels');
      this.channelIds = channelsRes.data.channels;
    },
    async [types.SUBSCRIBE_TO_CHANNEL](asset) {
      const messageStore = useMessageStore();
      try {
        const res = await this.deApi.put(`/home/channel/${asset.id}`);

        if (res?.status === 200) {
          messageStore[types.SET_APP_MESSAGE]({
            type: 'ChannelSubscribeMessage',
            op: 'add',
            assetName: asset.name || asset.title,
          });
          this.channelIds.push(asset.id);
        } else {
          throw res;
        }
      } catch (error) {
        messageStore[types.SET_APP_MESSAGE]({
          type: 'ChannelSubscribeErrorMessage',
          op: 'add',
          message: get(error, 'response.data.meta.message', error),
        });
      }
    },
    async [types.UNSUBSCRIBE_FROM_CHANNEL](asset) {
      const messageStore = useMessageStore();
      try {
        const res = await this.deApi.delete(`/home/channel/${asset.id}`);

        if (res?.status === 200) {
          messageStore[types.SET_APP_MESSAGE]({
            type: 'ChannelSubscribeMessage',
            op: 'remove',
            assetName: asset.name || asset.title,
          });
          const idx = this.channelIds.indexOf(asset.id);
          if (idx === -1) return;
          this.channelIds.splice(idx, 1);
        } else {
          throw res;
        }
      } catch (error) {
        messageStore[types.SET_APP_MESSAGE]({
          type: 'ChannelSubscribeErrorMessage',
          op: 'remove',
          message: get(error, 'response.data.meta.message', error),
        });
      }
    },
  },
});

export default useChannelStore;
