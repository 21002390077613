/**
 * These constants are available at runtime. This is NOT a private .env file and should not contain
 * any private keys.
 */

export const VUE_APP_LOCAL_API_US = 'https://app.local.discoveryeducation.com/studio/v1';
export const VUE_APP_LOCAL_API_UK = 'https://app.local.discoveryeducation.co.uk/studio/v1';
export const VUE_APP_LOCAL_API_CA = 'https://app.local.discoveryeducation.ca/studio/v1';
export const VUE_APP_LOCAL_DEAPI_US = 'https://api.local.discoveryeducation.com/v1';
export const VUE_APP_LOCAL_DEAPI_UK = 'https://api.local.discoveryeducation.co.uk/v1';
export const VUE_APP_LOCAL_DEAPI_CA = 'https://api.local.discoveryeducation.ca/v1';
export const VUE_APP_LOCAL_APPURL_US = 'https://app.local.discoveryeducation.com/learn';
export const VUE_APP_LOCAL_APPURL_UK = 'https://app.local.discoveryeducation.co.uk/learn';
export const VUE_APP_LOCAL_APPURL_CA = 'https://app.local.discoveryeducation.ca/learn';
export const VUE_APP_LOCAL_ADMIN_URL_US = 'https://admin.local.discoveryeducation.com';
export const VUE_APP_LOCAL_ADMIN_URL_UK = 'https://admin.local.discoveryeducation.co.uk';
export const VUE_APP_LOCAL_ADMIN_URL_CA = 'https://admin.local.discoveryeducation.ca';
export const VUE_APP_LOCAL_STUDIO_URL_US = 'https://studio.local.discoveryeducation.com';
export const VUE_APP_LOCAL_STUDIO_URL_UK = 'https://studio.local.discoveryeducation.com';
export const VUE_APP_LOCAL_STUDIO_URL_CA = 'https://studio.local.discoveryeducation.com';
export const VUE_APP_LOCAL_STATICURL_US = 'https://app.local.discoveryeducation.com/static';
export const VUE_APP_LOCAL_STATICURL_UK = 'https://app.local.discoveryeducation.co.uk/static';
export const VUE_APP_LOCAL_STATICURL_CA = 'https://app.local.discoveryeducation.ca/static';
export const VUE_APP_LOCAL_CONTENT_API = 'https://content.local.discoveryeducation.com';

export const VUE_APP_DEV_API_US = 'https://app.dev.discoveryeducation.com/studio/v1';
export const VUE_APP_DEV_API_UK = 'https://app.dev.discoveryeducation.co.uk/studio/v1';
export const VUE_APP_DEV_API_CA = 'https://app.dev.discoveryeducation.ca/studio/v1';
export const VUE_APP_DEV_DEAPI_US = 'https://api.dev.discoveryeducation.com/v1';
export const VUE_APP_DEV_DEAPI_UK = 'https://api.dev.discoveryeducation.co.uk/v1';
export const VUE_APP_DEV_DEAPI_CA = 'https://api.dev.discoveryeducation.ca/v1';
export const VUE_APP_DEV_APPURL_US = 'https://app.dev.discoveryeducation.com/learn';
export const VUE_APP_DEV_APPURL_UK = 'https://app.dev.discoveryeducation.co.uk/learn';
export const VUE_APP_DEV_APPURL_CA = 'https://app.dev.discoveryeducation.ca/learn';
export const VUE_APP_DEV_ADMIN_URL_US = 'https://admin.dev.discoveryeducation.com';
export const VUE_APP_DEV_ADMIN_URL_UK = 'https://admin.dev.discoveryeducation.co.uk';
export const VUE_APP_DEV_ADMIN_URL_CA = 'https://admin.dev.discoveryeducation.ca';
export const VUE_APP_DEV_STUDIO_URL_US = 'https://studio.dev.discoveryeducation.com';
export const VUE_APP_DEV_STUDIO_URL_UK = 'https://studio.dev.discoveryeducation.co.uk';
export const VUE_APP_DEV_STUDIO_URL_CA = 'https://studio.dev.discoveryeducation.ca';
export const VUE_APP_DEV_STATICURL_US = 'https://app.dev.discoveryeducation.com/static';
export const VUE_APP_DEV_STATICURL_UK = 'https://app.dev.discoveryeducation.co.uk/static';
export const VUE_APP_DEV_STATICURL_CA = 'https://app.dev.discoveryeducation.ca/static';
export const VUE_APP_DEV_CONTENT_API = 'https://content.dev.discoveryeducation.com';

export const VUE_APP_STAGE_API_US = 'https://app.stage.discoveryeducation.com/studio/v1';
export const VUE_APP_STAGE_API_UK = 'https://app.stage.discoveryeducation.co.uk/studio/v1';
export const VUE_APP_STAGE_API_CA = 'https://app.stage.discoveryeducation.ca/studio/v1';
export const VUE_APP_STAGE_DEAPI_US = 'https://api.stage.discoveryeducation.com/v1';
export const VUE_APP_STAGE_DEAPI_UK = 'https://api.stage.discoveryeducation.co.uk/v1';
export const VUE_APP_STAGE_DEAPI_CA = 'https://api.stage.discoveryeducation.ca/v1';
export const VUE_APP_STAGE_APPURL_US = 'https://app.stage.discoveryeducation.com/learn';
export const VUE_APP_STAGE_APPURL_UK = 'https://app.stage.discoveryeducation.co.uk/learn';
export const VUE_APP_STAGE_APPURL_CA = 'https://app.stage.discoveryeducation.ca/learn';
export const VUE_APP_STAGE_ADMIN_URL_US = 'https://admin.stage.discoveryeducation.com';
export const VUE_APP_STAGE_ADMIN_URL_UK = 'https://admin.stage.discoveryeducation.co.uk';
export const VUE_APP_STAGE_ADMIN_URL_CA = 'https://admin.stage.discoveryeducation.ca';
export const VUE_APP_STAGE_STUDIO_URL_US = 'https://studio.stage.discoveryeducation.com';
export const VUE_APP_STAGE_STUDIO_URL_UK = 'https://studio.stage.discoveryeducation.co.uk';
export const VUE_APP_STAGE_STUDIO_URL_CA = 'https://studio.stage.discoveryeducation.ca';
export const VUE_APP_STAGE_STATICURL_US = 'https://app.stage.discoveryeducation.com/static';
export const VUE_APP_STAGE_STATICURL_UK = 'https://app.stage.discoveryeducation.co.uk/static';
export const VUE_APP_STAGE_STATICURL_CA = 'https://app.stage.discoveryeducation.ca/static';
export const VUE_APP_STAGE_CONTENT_API = 'https://content.stage.discoveryeducation.com';

export const VUE_APP_QA_API_US = 'https://app.qa.discoveryeducation.com/studio/v1';
export const VUE_APP_QA_API_UK = 'https://app.qa.discoveryeducation.co.uk/studio/v1';
export const VUE_APP_QA_API_CA = 'https://app.qa.discoveryeducation.ca/studio/v1';
export const VUE_APP_QA_DEAPI_US = 'https://api.qa.discoveryeducation.com/v1';
export const VUE_APP_QA_DEAPI_UK = 'https://api.qa.discoveryeducation.co.uk/v1';
export const VUE_APP_QA_DEAPI_CA = 'https://api.qa.discoveryeducation.ca/v1';
export const VUE_APP_QA_APPURL_US = 'https://app.qa.discoveryeducation.com/learn';
export const VUE_APP_QA_APPURL_UK = 'https://app.qa.discoveryeducation.co.uk/learn';
export const VUE_APP_QA_APPURL_CA = 'https://app.qa.discoveryeducation.ca/learn';
export const VUE_APP_QA_ADMIN_URL_US = 'https://admin.qa.discoveryeducation.com';
export const VUE_APP_QA_ADMIN_URL_UK = 'https://admin.qa.discoveryeducation.co.uk';
export const VUE_APP_QA_ADMIN_URL_CA = 'https://admin.qa.discoveryeducation.ca';
export const VUE_APP_QA_STUDIO_URL_US = 'https://studio.qa.discoveryeducation.com';
export const VUE_APP_QA_STUDIO_URL_UK = 'https://studio.qa.discoveryeducation.co.uk';
export const VUE_APP_QA_STUDIO_URL_CA = 'https://studio.qa.discoveryeducation.ca';
export const VUE_APP_QA_STATICURL_US = 'https://app.qa.discoveryeducation.com/static';
export const VUE_APP_QA_STATICURL_UK = 'https://app.qa.discoveryeducation.co.uk/static';
export const VUE_APP_QA_STATICURL_CA = 'https://app.qa.discoveryeducation.ca/static';
export const VUE_APP_QA_CONTENT_API = 'https://content.qa.discoveryeducation.com';

export const VUE_APP_PROD_API_US = 'https://app.discoveryeducation.com/studio/v1';
export const VUE_APP_PROD_API_UK = 'https://app.discoveryeducation.co.uk/studio/v1';
export const VUE_APP_PROD_API_CA = 'https://app.discoveryeducation.ca/studio/v1';
export const VUE_APP_PROD_DEAPI_US = 'https://api.discoveryeducation.com/v1';
export const VUE_APP_PROD_DEAPI_UK = 'https://api.discoveryeducation.co.uk/v1';
export const VUE_APP_PROD_DEAPI_CA = 'https://api.discoveryeducation.ca/v1';
export const VUE_APP_PROD_APPURL_US = 'https://app.discoveryeducation.com/learn';
export const VUE_APP_PROD_APPURL_UK = 'https://app.discoveryeducation.co.uk/learn';
export const VUE_APP_PROD_APPURL_CA = 'https://app.discoveryeducation.ca/learn';
export const VUE_APP_PROD_ADMIN_URL_US = 'https://admin.discoveryeducation.com';
export const VUE_APP_PROD_ADMIN_URL_UK = 'https://admin.discoveryeducation.co.uk';
export const VUE_APP_PROD_ADMIN_URL_CA = 'https://admin.discoveryeducation.ca';
export const VUE_APP_PROD_STUDIO_URL_US = 'https://studio.discoveryeducation.com';
export const VUE_APP_PROD_STUDIO_URL_UK = 'https://studio.discoveryeducation.co.uk';
export const VUE_APP_PROD_STUDIO_URL_CA = 'https://studio.discoveryeducation.ca';
export const VUE_APP_PROD_STATICURL_US = 'https://app.discoveryeducation.com/static';
export const VUE_APP_PROD_STATICURL_UK = 'https://app.discoveryeducation.co.uk/static';
export const VUE_APP_PROD_STATICURL_CA = 'https://app.discoveryeducation.ca/static';
export const VUE_APP_PROD_CONTENT_API = 'https://content.discoveryeducation.com';

export const VUE_APP_WWW_DISCOVERY_EDUCATION_URL_US = 'https://www.discoveryeducation.com';
export const VUE_APP_WWW_DISCOVERY_EDUCATION_URL_CA = 'https://www.discoveryeducation.ca';
export const VUE_APP_WWW_DISCOVERY_EDUCATION_URL_UK = 'https://www.discoveryeducation.co.uk';
export const VUE_APP_THUMBNAILS_CLOUDFRONT_DISTRIBUTION_URL = '//d1m0scxbrw6oep.cloudfront.net/thumbnails/';

export const VUE_APP_GTM_ID = 'GTM-PPDBVW5';

export const VUE_APP_LOCAL_SEGMENTKEY_US = 'UbOVQAQpyFyCrqrX7BXqk5veRlAuLyb1';
export const VUE_APP_DEV_SEGMENTKEY_US = 'XLKrTpU6fvfcUNYCgKUZHowF1hpzYDZj';
export const VUE_APP_QA_SEGMENTKEY_US = 'MKWeXhTSyhOMA4MP7RetqJdj9meSYRVi';
export const VUE_APP_STAGE_SEGMENTKEY_US = 'Fvf1ZjcHPuLQOfN6RwQjmMtXvX1adiHr';
export const VUE_APP_PROD_SEGMENTKEY_US = 'DpWRQAJPHJveGRaP8PZRQKCnwotom007';

export const VUE_APP_LOCAL_SEGMENTKEY_UK = 'wPUDbWnXC1dj3HnyyN4GR6qapZJtRvJb';
export const VUE_APP_DEV_SEGMEKEY_UK = 'Uc4zWdkdujhpsvH8qV4sXRZD3cYud2Bo';
export const VUE_APP_QA_SEGMENKEY_UK = 'IW7McTUqPBi9FGqOczKSJmvsPloDmANo';
export const VUE_APP_STAGE_SEGMENTKEY_UK = 'q2EdqW9e3QxpiyQIi8J1to6IZGYjZZtw';
export const VUE_APP_PROD_SEGMENTKEY_UK = 'HbpZ1S51xgKfjEdQVqIuylKlWqSRnVLR';

export const VUE_APP_LOCAL_SEGMENTKEY_CA = 'aYVbSCEKWMm92FLrztknv0qnRniz9tPL';
export const VUE_APP_DEV_SEGMENTKEY_CA = 'XI0mUB6Ph9DXDYS8HN9sm7124XnEvsUq';
export const VUE_APP_QA_SEGMENTKEY_CA = '9CEB6PdwEUldw4eysQpymvPJ1Jhhp8K9';
export const VUE_APP_STAGE_SEGMENTKEY_CA = 'VpGL7FGQ0UpB19kHUYAPwlzoe0g2gyek';
export const VUE_APP_PROD_SEGMENTKEY_CA = 'YMkGqLxADXXd2GaLQF3DW5WAJ97fOamy';
